import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const LoadingScreen: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-screen opacity-75 fixed top-0 justify-center h-full min-h-screen bg-black">
      <ArrowPathIcon className="w-16 h-16 mt-6 text-white animate-spin" />
      <div className="text-4xl text-white font-bold opacity-100">
        Carregando...
      </div>
    </div>
  );
};

export default LoadingScreen;
