import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetPix } from '../queries/bank';
import { useGetOrderDetails, useGetStatusPix } from '../queries/order';
import { usePixStore } from '../stores/pix';
import { useUserStore } from '../stores/user';
import LoadingScreen from './LoadingScreen';
import { useOrdersStore } from '../stores/orders';
import copy from 'copy-to-clipboard';
import { AxiosError } from 'axios';
import { timerDuration } from '@onbeefapp/constants';
import { Toast } from '@onbeefapp/constants';

// {
//     "message": "Pedido registrado com sucesso",
//     "id": "eb1fc9f8-9fed-44e7-b45a-9b9970e2d237",
//     "code": "M0X5UXKHJQ",
//     "customer_id": "c73e9439-c4f3-47ff-8873-ae52aa1f6608",
//     "payment_codes": {
//         "qr_code": "00020101021226820014br.gov.bcb.pix2560pix.stone.com.br/pix/v2/fc971f4b-efdf-426a-9879-894874dc7503520400005303986540595.755802BR59252T INTERMEDIACAO DE SERVI6014RIO DE JANEIRO62290525paclf8y0e0s2gpl1fldfsu8gg6304B3A1",
//         "qr_code_url": "https://api.pagar.me/core/v5/transactions/tran_1M0XjVezi0uO7oa6/qrcode?payment_method=pix",
//         "expires_at": "2023-03-15T21:27:14Z",
//         "transaction_type": "PIX",
//         "amount": 9575,
//         "status": "WAITING_PAYMENT"
//     }
// }

type Props = {
  message: string;
  id: string;
  code: string;
  customer_id: string;
  payment_codes: {
    qr_code: string;
    qr_code_url: string;
    expires_at: string;
    transaction_type: string;
    amount: number;
    status: string;
  };
};

const PixPayment: React.FC<any> = (
  {
    //   message,
    //   id,
    //   code,
    //   customer_id,
    //   payment_codes,
  },
) => {
  const navigate = useNavigate();
  const params = useParams();
  const order_id = params.id ?? '';

  const isOrderPaid = useOrdersStore((state) => state.isPaid);
  const addPaidOrder = useOrdersStore((state) => state.addPaid);

  const user = useUserStore((state) => state.user);
  const generatedPix = usePixStore((state) => state.generatedPix);
  const timeGenerated = usePixStore((state) => state.timeGenerated);
  const setGeneratedPix = usePixStore((state) => state.setGeneratedPix);

  React.useEffect(() => {
    if (isOrderPaid(order_id)) {
      navigate(`/success/${order_id}`, { replace: true });
    }
  }, []);

  // countdown timer
  const [timer, setTimer] = React.useState('05:00');
  const [seconds, setSeconds] = React.useState<number>();
  const [startSeconds, setStartSeconds] = React.useState(300);
  const Ref = React.useRef<any>();

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.parse(new Date() as any);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: any) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total > 0) {
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds),
      );
    } else {
      setSeconds(total / 1000);
    }
  };

  const clearTimer = (e: any) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + seconds!);
    return deadline;
  };

  React.useEffect(() => {
    clearTimer(getDeadTime());
  }, [seconds]);

  React.useEffect(() => {
    setStartSeconds(300);
    setSeconds(300);
    var formatted = timerDuration(300);
    setTimer(formatted);
  }, []);

  const { data: statusPixData, refetch: checkStatusPix } =
    useGetStatusPix(order_id);

  React.useEffect(() => {
    console.log({ statusPixData });
    if (statusPixData?.data.status_payment === 'PAID') {
      // redirect to success page
      addPaidOrder(order_id);
      navigate(`/success/${order_id}`, { replace: true });
    }
  }, [statusPixData]);

  React.useEffect(() => {
    const run = async () => {
      let i = 0;
      const sleep = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));
      while (i < 30) {
        console.log(i);
        await checkStatusPix();
        await sleep(10000);
        i++;
      }
    };
    run();
  }, []);

  let code = generatedPix[order_id]?.payment_codes?.qr_code ?? '';

  function copyCode() {
    copy(code);

    Toast.success('Código copiado com sucesso', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        margin: '10px',
        background: '#1ea563',
        borderRadius: '10px',
      },
      theme: 'colored',
    });
  }

  const pixAlreadyExists = generatedPix[order_id ?? ''] !== undefined;

  // only fetch the data if the pix doesn't already exist
  const pix = useGetPix(
    pixAlreadyExists ? null : order_id ?? null,
    user.name,
    user.phone,
    user.taxpayer_id_number,
    user.email,
  );

  React.useEffect(() => {
    if (pix.data) {
      console.log('pix', pix.data);
      setGeneratedPix(order_id!, pix.data);
    }
    // const status = await getStatusPix();
  }, [pix.isSuccess, pix.data]);

  const erroPix = generatedPix[order_id]?.payment_codes?.error;

  React.useEffect(() => {
    if (pix.error instanceof AxiosError) {
      navigate(`/error/${order_id}`, { replace: true });
    }
  }, [pix.error]);

  if (erroPix) {
    return (
      <div className="w-full h-auto bg-white rounded-[10px] p-4 mt-4 flex flex-col">
        <div className="text-1xl font-bold">Erro ao gerar o Pix</div>
        <div className="flex flex-col justify-between rounded-lg border-[2px] border-gray-400 p-4 items-center mt-2">
          {generatedPix[order_id]?.payment_codes?.error}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-auto bg-white rounded-[10px] p-4 mt-4 flex flex-col">
      <div className="text-1xl font-bold">Pedido aguardando pagamento</div>
      <div className="flex flex-col justify-between rounded-lg border-[2px] border-gray-400 p-4 items-center mt-2">
        Copie o código abaixo e utilize o Pix Copia e Cola ou escaneie o QR CODE
        no aplicativo que você vai fazer o pagamento:
        <br />
        {pix.isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <img
              src={generatedPix[order_id]?.payment_codes.qr_code_url}
              alt="pix"
              className="w-[120px] h-[120px]"
            />
            <br />
            <div className="text-2xl font-bold">Como fazer o pagamento?</div>
            <br />1 - Copie o código abaixo
            <br /> 2 - Acesse seu banco ou app de pagamentos
            <br /> 3 - Escolha pagar via pix copia e cola
            <br /> 4 - Cole o código para realizar o pagamento
            <br />
            <br />
            {/* PIX CODe */}
            {/* <div className="text-1xl font-bold w-full truncate p-2 bg-gray-500 rounded-md flex flex-col"> */}
            {/* {generatedPix[order_id]?.payment_codes.qr_code} */}
            {/* copy to clipboard onClick*/}
            {/* </div> */}
            {/* button to copy to clipboard */}
            <button
              className="bg-[#B21E1E] text-white rounded-md p-2 mt-2"
              onClick={copyCode}
            >
              Copiar código
            </button>
            <br />
            Você tem até 5 minutos para fazer o pagamento, seu tempo é de:
            <br />
            {/* countdown timer updates every second */}
            <div className="text-2xl font-bold">{timer}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default PixPayment;
