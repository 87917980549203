import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import api from '../../services/api';
import { DeliveryMethodResponse, PixResponse } from './types';
// import { CacheResponse } from './types';

// PIX
// {
//    "order_id" : "{{order_id}}",
//    "method_payment":"CHECKOUT_PIX",
//    "name":"Paulo Victor",
//    "phone":"81986578987",
//    "taxpayer_id_number":"10357074467",
//    "email":"paullo@gmail.com"
// }

export const getStatusPix = async (id: string) => {
  const { data } = await api.get<PixResponse>('/customers/orders/status/' + id);
  return data;
};

async function getPix(ctx: QueryFunctionContext) {
  const [, order_id, name, phone, taxpayer_id_number, email] = ctx.queryKey;
  if (!order_id || !name || !phone || !taxpayer_id_number || !email) return;
  const { data } = await api.post<PixResponse>(
    // `/customers/orders/link/generate`,
    '/customers/orders/link/pay',
    {
      order_id,
      method_payment: 'CHECKOUT_PIX',
      name,
      phone,
      taxpayer_id_number,
      email,
    },
  );
  return data;
}

// {
//   "order_id" : "{{order_id}}",
//   "method_payment":"CHECKOUT_CREDIT_CARD",
//   "payment":{
//      "installments":1,
//      "card":{
//         "id":"8e3d4b53-d30f-4a24-8626-4ad53d4109d9",
//         "cvv":"123"
//      }
//   },
//   "name":"Paulo Victor",
//   "phone":"81986578987",
//   "taxpayer_id_number":"10357074467",
//   "email":"paullo@gmail.com"
// }

async function getCreditCard(ctx: QueryFunctionContext) {
  const [, order_id, payment, name, phone, taxpayer_id_number, email] =
    ctx.queryKey;
  if (!order_id || !name || !phone || !taxpayer_id_number || !email) return;
  const { data } = await api.post(`/customers/orders/link`, {
    order_id,
    method_payment: 'CHECKOUT_CREDIT_CARD',
    payment,
    name,
    phone,
    taxpayer_id_number,
    email,
  });
  return data;
}

async function getDeliveryMethods(ctx: QueryFunctionContext) {
  const [, merchantId] = ctx.queryKey;
  if (!merchantId) return;
  const { data } = await api.get<DeliveryMethodResponse[]>(
    `/all/merchants/${merchantId}/delivery-method`,
  );
  return data;
}

export function useDeliveryMethods(id?: string) {
  return useQuery(['getDeliveryMethods', id], getDeliveryMethods, {
    refetchOnWindowFocus: true,
    staleTime: 2000000,
  });
}

export function useGetPix(
  order_id: string | null,
  name: string,
  phone: string,
  taxpayer_id_number: string,
  email: string,
) {
  return useQuery(
    ['getPix', order_id, name, phone, taxpayer_id_number, email],
    getPix,
    {
      refetchOnWindowFocus: false,
      staleTime: 2000000,
    },
  );
}

export function useGetCreditCard(
  order_id: string,
  payment: {
    installments: number;
    card: {
      id: string;
      cvv: string;
    };
  },
  name: string,
  phone: string,
  taxpayer_id_number: string,
  email: string,
) {
  return useQuery(
    [
      'getCreditCard',
      order_id,
      payment,
      name,
      phone,
      taxpayer_id_number,
      email,
    ],
    getCreditCard,
    {
      refetchOnWindowFocus: false,
      staleTime: 2000000,
    },
  );
}
