import CheckCircleIcon from '@heroicons/react/24/outline/CheckCircleIcon';
import XCircleIcon from '@heroicons/react/24/outline/XCircleIcon';
import { Button } from '@material-tailwind/react';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import { useGetOrderDetails } from '../../queries/order';

const SuccessPage: React.FC = () => {
  const params = useParams();

  const { data, isSuccess, isLoading } = useGetOrderDetails(params.orderid);

  return (
    <div className="absolute top-0 left-0 right-0 z-10 w-screen h-screen p-6 bg-white">
      <div className="flex flex-col items-center justify-center h-full">
        {isLoading ? (
          <LoadingScreen />
        ) : isSuccess ? (
          <>
            <CheckCircleIcon className="w-20 h-20 text-green-500" />
            <h1 className="mt-10 text-2xl font-bold text-center text-gray-700">
              Pedido pago com sucesso
            </h1>
            {data ? (
              <Button
                variant="filled"
                placeholder={'acompanhe seu pedido'}
                className="mt-6 bg-green-500"
                onClick={() => {
                  window.open(
                    `https://catalogo.onbeefapp.com.br/pedidos#${data.code}`,
                  );
                }}
              >
                Acompanhe seu pedido.
              </Button>
            ) : null}
          </>
        ) : (
          <>
            <XCircleIcon className="w-20 h-20 text-red-500" />
            <h1 className="mt-10 text-2xl font-bold text-center text-gray-700">
              Pedido não existe
            </h1>
          </>
        )}
      </div>
    </div>
  );
};

export default SuccessPage;
