import { ThemeProvider } from '@material-tailwind/react';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { queryClient } from './services/queryClient';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import 'config/global.css';

const isOnbeef = window.location.href.includes('onbeefapp.');

const color = !isOnbeef ? '#1b9051' : '#a62605';
const lightColor = !isOnbeef ? '#b7ebcf' : '#e8c3ba';
const secondaryColor = !isOnbeef ? '#9c4dcc' : '#821F05';

const theme = {
  color: 'green',
  input: {
    styles: {
      base: {
        container: {
          minWidth: 'min-w-[100px]',
        },
      },
    },
  },
  button: {
    defaultProps: {
      color: '#B21E1E',
    },
  },
};

const isProduction = window.location.href.includes('https://');

const rollbarConfig = {
  accessToken: '58f643fab2434c35a62b849fb1017e90',
  environment: isProduction ? 'production' : 'testenv',
};
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider value={theme as any}>
      <QueryClientProvider client={queryClient}>
        <Provider config={rollbarConfig}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Provider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
