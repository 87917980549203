import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select, TextInput } from '@onbeef/components/input';
import { Toast } from '@onbeefapp/constants';
import React, { useEffect } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { Resolver, useForm, FormProvider } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useNavigate, useParams } from 'react-router-dom';
import Yup from '@onbeefapp/constants/src/utils/Yup';
import { useCalculateFeesFinalValue } from '../hooks/useCalculateFeesFinalValue';
import { useDeliveryMethods } from '../queries/bank';
import { useMerchantCache } from '../queries/home';
import { useGetOrderDetails } from '../queries/order';
import { useCreateCard, usePayWithCreditCard } from '../queries/payment-card';
import { useOrdersStore } from '../stores/orders';
import { usePaymentMethodStore } from '../stores/payment-method';
import { useUserStore } from '../stores/user';
import getAddressInfo from '../utils/GoogleAddressUtils';
import { estados } from '../utils/constants';
import { maskCEP, maskCreditCard, maskExpiryDate } from '../utils/input-masks';
import LoadingScreen from './LoadingScreen';

type CreditCardFormData = {
  name: string;
  cardNumber: string;
  expirationDate: string;
  cvv: string;
  street_name: string;
  street_number: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  latitude: string;
  longitude: string;
  street_complement: string;
  street_reference: string;
};

type Props = {
  goBack: () => void;
};

const CreditCardPayment = ({ goBack }: Props) => {
  const params = useParams();
  const order = useGetOrderDetails(params.id);
  const navigate = useNavigate();
  const [cvv, setCvv] = React.useState('');
  const [centsOpen, setCentsOpen] = React.useState(false);
  const selectedCreditCard = usePaymentMethodStore(
    (state) => state.selectedCreditCard,
  );
  const setSelectedCreditCard = usePaymentMethodStore(
    (state) => state.setSelectedCreditCard,
  );
  const paymentMethod = usePaymentMethodStore((state) => state.paymentMethod);
  const setPaymentMethod = usePaymentMethodStore(
    (state) => state.setPaymentMethod,
  );

  const merchantCache = useMerchantCache(order.data?.merchant_id);
  const deliveryMethods = useDeliveryMethods(order.data?.merchant_id);

  const addPaidOrder = useOrdersStore((state) => state.addPaid);

  const finalValueWithFees = useCalculateFeesFinalValue(
    merchantCache.data,
    order.data,
    'CHECKOUT_CREDIT_CARD',
    order.data?.freight_amount,
    parseFloat(order.data?.balance_amount ?? '0'),
  );

  console.log('finalValueWithFees', finalValueWithFees);

  const payWithCreditCard = usePayWithCreditCard();

  console.log('payWithCreditCard', payWithCreditCard.data);
  console.log(
    'payWithCreditCard',
    payWithCreditCard.isError,
    payWithCreditCard.error,
  );

  const success = () => {
    // show sucess toast
    Toast.success('Pagamento autorizado com sucesso!');
    setSelectedCreditCard(null);
    setPaymentMethod(null);
  };

  React.useEffect(() => {
    console.log({ payWithCreditCard });
    if (
      payWithCreditCard.data?.payment_codes.status ===
      'AUTHORIZED_PENDING_CAPTURE'
    ) {
      // cents
      navigate(`/validate/${order.data?.id}`, { replace: true });

      addPaidOrder(params.id);
    }
  }, [
    payWithCreditCard.data,
    payWithCreditCard.isError,
    payWithCreditCard.error,
    payWithCreditCard.isSuccess,
  ]);

  const validationSchema = Yup.object<CreditCardFormData>().shape({
    name: Yup.string().required(),
    cardNumber: Yup.string().required(),
    expirationDate: Yup.string()
      .required()
      .matches(
        /^(0[1-9]|1[0-2])\/\d{2}$/,
        'Must be a valid expiration date (MM/YY)',
      ),
    cvv: Yup.string()
      .required()
      .matches(/^\d{3}$/, 'Must be a valid 3-digit CVV'),
    street_name: Yup.string().required('Rua é obrigatorio'),
    street_number: Yup.string().required('Numero é obrigatorio'),
    neighborhood: Yup.string().required('Bairro é obrigatorio'),
    city: Yup.string().required('Cidade é obrigatorio'),
    state: Yup.string().required('Estado é obrigatorio'),
    zip: Yup.string().required('CEP é obrigatorio'),
    street_complement: Yup.string().required('Complemento é obrigatorio'),
    latitude: Yup.string().required('Latitude é obrigatorio'),
    longitude: Yup.string().required('Longitude é obrigatorio'),
    street_reference: Yup.string().required('dsa'),
    country: Yup.string().required('País é obrigatorio'),
  });

  const methods = useForm<CreditCardFormData>({
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });
  const errors = methods.formState.errors;

  const user = useUserStore((state) => state.user);

  useFormPersist('card-' + user?.phone, {
    watch: methods.watch,
    setValue: methods.setValue,
    exclude: ['cvv'],
  });

  const { data: dataVisitor } = useVisitorData();
  const { mutate, isLoading, isSuccess, data, isError, error } =
    useCreateCard();

  useEffect(() => {
    if (isSuccess) {
      console.log(data);
      if (data && data?.card?.id) {
        payWithCreditCard.mutate({
          email: user?.email,
          name: user?.name,
          phone: user?.phone,
          taxpayer_id_number: user?.taxpayer_id_number,
          method_payment: 'CHECKOUT_CREDIT_CARD',
          order_id: order.data?.id ?? '',
          payment: {
            installments: 1,
            card: {
              id: data?.card.id,
              cvv: methods.getValues('cvv'),
            },
          },
        });
      } else {
        throw Error('Card not created = ' + JSON.stringify(data));
      }
      // goBack();
    }
  }, [data]);

  const address = order?.data?.address?.[0];

  useEffect(() => {
    if (address) {
      methods.setValue('street_name', address.street_name);
      methods.setValue('street_number', address.street_number);
      methods.setValue('neighborhood', address.neighborhood);
      methods.setValue('city', address.city);
      methods.setValue('state', address.state);
      methods.setValue('zip', address.zip);
      methods.setValue('street_complement', address.street_complement);
      methods.setValue('latitude', address.latitude);
      methods.setValue('longitude', address.longitude);
      methods.setValue('street_reference', address.street_reference);
      methods.setValue('country', address.country);
    }
  }, []);

  const onSubmit = (data: CreditCardFormData) => {
    console.log(data);
    const billing_address = {
      address: data.street_name,
      complement: data.street_complement,
      zip_code: data.zip,
      city: data.city,
      state: data.state,
      country: 'BR',
    };
    const payload = {
      token: dataVisitor?.visitorId,
      payment_method: 'CREDIT_CARD',
      number: data.cardNumber,
      holder_name: data.name.toUpperCase(),
      holder_document: user.taxpayer_id_number,
      email: user.email,
      exp_month: data.expirationDate.split('/')[0],
      exp_year: data.expirationDate.split('/')[1],
      cvv: data.cvv,
      billing_address: billing_address,
    };
    mutate(payload);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="max-w-md mx-auto mt-6"
      >
        {isLoading || (payWithCreditCard.isLoading && <LoadingScreen />)}
        Cartão
        <div className="w-full mt-2rounded-md">
          <TextInput
            name="name"
            customLabel="Nome no cartão"
            validationSchema={{}}
            type={'text'}
            autoComplete="name"
            className="h-[42px] w-full text-sm"
            error={errors?.name !== undefined}
          />
          <div className="text-red-400">
            {errors?.name && errors?.name?.message}
          </div>
        </div>
        <div className="w-full mt-2 rounded-md">
          <TextInput
            name="cardNumber"
            customLabel="Número do cartão"
            validationSchema={{}}
            type={'text'}
            autoComplete="cardNumber"
            className="h-[42px] w-full text-sm"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              methods.setValue('cardNumber', maskCreditCard(e.target.value));
              methods.trigger('cardNumber');
            }}
            error={errors?.cardNumber !== undefined}
          />
          <div className="text-red-400">
            {errors?.cardNumber && errors?.cardNumber?.message}
          </div>
        </div>
        <div className="grid w-full grid-cols-6 gap-2 mt-2">
          <div className="w-full col-span-3 rounded-md">
            <TextInput
              name="expirationDate"
              customLabel="Data de expiração"
              validationSchema={{}}
              type={'text'}
              autoComplete="expirationDate"
              className="h-[42px] w-full text-sm"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                methods.setValue(
                  'expirationDate',
                  maskExpiryDate(e.target.value),
                );
                methods.trigger('expirationDate');
              }}
              error={errors?.expirationDate !== undefined}
            />
            <div className="text-red-400">
              {errors?.expirationDate && errors?.expirationDate?.message}
            </div>
          </div>
          <div className="w-full col-span-3 rounded-md">
            <TextInput
              name="cvv"
              customLabel="CVV"
              validationSchema={{}}
              type={'number'}
              autoComplete="cvv"
              className="h-[42px] w-full text-sm"
              error={errors?.cvv !== undefined}
            />
            <div className="text-red-400">
              {errors?.cvv && errors?.cvv?.message}
            </div>
          </div>
        </div>
        <div className="grid w-full grid-cols-6 gap-2 mt-6">
          <div className="w-full col-span-6 bg-white rounded-md">
            Endereço
            <ReactGoogleAutocomplete
              language="pt-BR"
              className="h-[42px] w-full text-sm mt-2 bg-white border-[1px] border-gray-400 px-2 rounded-md"
              apiKey={'AIzaSyCO4g_RKatdYgx-hzWre0NPJfdyxAX8RGc'}
              options={{
                types: ['address'],
                componentRestrictions: { country: 'br' },
              }}
              defaultValue={methods.getValues('street_name')}
              onPlaceSelected={(place) => {
                const latitude = place.geometry.location.lat();
                const longitude = place.geometry.location.lng();
                console.log(place, latitude, longitude);
                const result = getAddressInfo(place.address_components);
                methods.setValue('latitude', latitude);
                methods.setValue('longitude', longitude);
                methods.setValue('neighborhood', result.neighborhood);
                methods.setValue('city', result.city);
                methods.setValue('state', result.state);
                methods.setValue('zip', result.zipCode);
                methods.setValue('country', 'Brasil');
                methods.setValue('street_name', result.streetName);
              }}
            />
          </div>
        </div>
        <div className="w-full mt-2 rounded-md">
          <TextInput
            name="neighborhood"
            customLabel="Bairro"
            validationSchema={{}}
            type={'text'}
            autoComplete="bairro"
            className="h-[42px] w-full text-sm"
            error={errors?.neighborhood !== undefined}
          />
          <div className="text-red-400">
            {errors?.neighborhood && errors?.neighborhood?.message}
          </div>
        </div>
        <div className="grid w-full grid-cols-6 gap-2 mt-2">
          <div className="w-full col-span-3 rounded-md">
            <TextInput
              name="city"
              defaultValue={address?.city}
              validationSchema={{}}
              customLabel="Cidade"
              type={'text'}
              autoComplete="cidade"
              className="h-[42px] !min-w-0 w-full text-sm"
              error={errors?.city !== undefined}
            />
            <div className="text-red-400">
              {errors?.city && errors?.city?.message}
            </div>
          </div>
          <div className="w-full col-span-3 bg-white rounded-md">
            <Select
              customLabel={'Estado'}
              className={`h-[42px] border-[1px] mt-1 border-gray-400 rounded-md !min-w-0 w-full text-sm bg-white ${
                errors.state ? 'border-red-500' : ''
              }`}
              placeholder={'Selecione um estado'}
              options={estados.map((estado) => {
                return {
                  label: estado.estado,
                  value: estado.id,
                };
              })}
              id="state"
              name="state"
            />
            <div className="text-red-400">
              {errors?.state && errors?.state?.message}
            </div>
          </div>
        </div>
        <div className="w-full mt-2 rounded-md">
          <TextInput
            name="zip"
            customLabel="CEP"
            validationSchema={{}}
            defaultValue={address?.zip}
            type={'text'}
            autoComplete="CEP"
            className="h-[42px] text-sm"
            error={errors?.zip !== undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              methods.setValue('zip', maskCEP(e.target.value));
              methods.trigger('zip');
            }}
          />
          <div className="text-red-400">
            {errors?.zip && errors?.zip?.message}
          </div>
        </div>
        <div className="w-full mt-2 rounded-md">
          <TextInput
            name="street_number"
            customLabel="Número"
            validationSchema={{}}
            defaultValue={address?.street_number}
            type={'number'}
            autoComplete="numero"
            className="h-[42px] !min-w-0 w-full text-sm"
            error={errors?.street_number !== undefined}
          />
          <div className="text-red-400">
            {errors?.street_number && errors?.street_number?.message}
          </div>
        </div>
        <div className="w-full mt-2 rounded-md">
          <TextInput
            name="street_complement"
            customLabel="Complemento"
            validationSchema={{}}
            type={'text'}
            autoComplete="complemento"
            className="h-[42px] text-sm"
            error={errors?.street_complement !== undefined}
          />
          <div className="text-red-400">
            {errors?.street_complement && errors?.street_complement?.message}
          </div>
        </div>
        {/* button to submit */}
        <button
          className="flex flex-row justify-center text-[#b21e1e] items-center mt-4 rounded-lg border-2 border-[#b21e1e] w-full h-[42px] cursor-pointer hover:bg-[#b21e1e] hover:text-white"
          type="submit"
        >
          {/* TODO: voltar a cadastrar cartao */}
          {/* CADASTRAR CARTÃO */}
          FINALIZAR PAGAMENTO
        </button>
        {data?.error ? (
          <div
            className="relative px-4 py-3 mt-4 text-red-700 bg-red-100 border border-red-400 rounded"
            role="alert"
          >
            <strong className="font-bold">Erro!</strong>
            <span className="block sm:inline"> {data?.error.toString()}</span>
          </div>
        ) : null}
        {payWithCreditCard?.error ? (
          <div
            className="relative px-4 py-3 mt-4 text-red-700 bg-red-100 border border-red-400 rounded"
            role="alert"
          >
            <strong className="font-bold">Erro!</strong>
            <span className="block sm:inline">
              {' '}
              {payWithCreditCard?.error.toString()}
            </span>
          </div>
        ) : null}
      </form>
    </FormProvider>
  );
};

export default CreditCardPayment;
