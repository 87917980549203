import axios from 'axios';
import { useUserStore } from '../stores/user';
import { Toast } from '@onbeefapp/constants';

const api = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_API_URL,
});

// Add a request interceptor
api.interceptors.request.use(function (config) {
  const token = useUserStore.getState().access_token;
  if (config.headers) {
    (config.headers as any)['Authorization'] = 'Bearer ' + token;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      alert('NETWORK ERROR');
    } else {
      const code = error.response.status;
      const originalRequest = error.config;

      if ((code === 401 || code === 400) && !originalRequest._retry) {
        let errorMessage = error.response.data.error;
        if (errorMessage !== undefined && errorMessage.constructor === Array) {
          errorMessage = errorMessage.join(', ');
        }

        Toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        originalRequest._retry = true;
      }

      return Promise.reject(error);
    }
  },
);

export default api;
