// Mask Expiry Date credit card (MM/YY)
export const maskExpiryDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{2})\d+?$/, '$1');
};

// Mask Credit Card (0000 0000 0000 0000)
export const maskCreditCard = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2');
};

// 000.000.000-00
export const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};
// 00.000.000/0000-00
export const maskCNPJ = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};

// (00) 00000-0000
export const maskPhone = (value: any) => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
};

// 00000-000
export const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');
};

// 00/00/0000
export const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1');
};

// Aceita apenas que letras sejam digitadas
export const maskOnlyLetters = (value: any) => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '');
};

// Aceita apenas números
export const maskOnlyNumbers = (value: any) => {
  return value.replace(/\D/g, '');
};
