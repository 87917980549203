import * as React from 'react';
import { usePaymentMethodStore } from '../stores/payment-method';
import { useCalculateFeesFinalValue } from '../hooks/useCalculateFeesFinalValue';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeliveryMethods } from '../queries/bank';
import { useMerchantCache } from '../queries/home';
import { useGetOrderDetails } from '../queries/order';
import LoadingScreen from './LoadingScreen';
import { usePayWithCreditCard } from '../queries/payment-card';
import { useUserStore } from '../stores/user';
import { useOrdersStore } from '../stores/orders';
import { TextInput } from '@onbeef/components/input';
import { Toast } from '@onbeefapp/constants';
import { FormProvider, useForm } from 'react-hook-form';

const PayWithCreditCard = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [cvv, setCvv] = React.useState('');
  const [centsOpen, setCentsOpen] = React.useState(false);
  const selectedCreditCard = usePaymentMethodStore(
    (state) => state.selectedCreditCard,
  );
  const setSelectedCreditCard = usePaymentMethodStore(
    (state) => state.setSelectedCreditCard,
  );
  const paymentMethod = usePaymentMethodStore((state) => state.paymentMethod);
  const setPaymentMethod = usePaymentMethodStore(
    (state) => state.setPaymentMethod,
  );

  const user = useUserStore((state) => state.user);
  const order = useGetOrderDetails(params.id);
  const merchantCache = useMerchantCache(order.data?.merchant_id);
  const deliveryMethods = useDeliveryMethods(order.data?.merchant_id);

  const addPaidOrder = useOrdersStore((state) => state.addPaid);

  const finalValueWithFees = useCalculateFeesFinalValue(
    merchantCache.data,
    order.data,
    'CHECKOUT_CREDIT_CARD',
    order.data?.freight_amount,
    parseFloat(order.data?.balance_amount ?? '0'),
  );

  console.log('finalValueWithFees', finalValueWithFees);

  const payWithCreditCard = usePayWithCreditCard();

  const methods = useForm();

  const onSubmit = () => {
    if (selectedCreditCard) {
      payWithCreditCard.mutate({
        email: user?.email,
        name: user?.name,
        phone: user?.phone,
        taxpayer_id_number: user?.taxpayer_id_number,
        method_payment: 'CHECKOUT_CREDIT_CARD',
        order_id: order.data?.id ?? '',
        payment: {
          installments: 1,
          card: {
            id: selectedCreditCard,
            cvv: cvv,
          },
        },
      });
    }
  };

  console.log('payWithCreditCard', payWithCreditCard.data);
  console.log(
    'payWithCreditCard',
    payWithCreditCard.isError,
    payWithCreditCard.error,
  );

  const success = () => {
    // show sucess toast
    Toast.success('Pagamento autorizado com sucesso!');
    setSelectedCreditCard(null);
    setPaymentMethod(null);
  };

  React.useEffect(() => {
    console.log({ payWithCreditCard });
    if (
      payWithCreditCard.data?.payment_codes.status ===
      'AUTHORIZED_PENDING_CAPTURE'
    ) {
      // cents
      navigate(`/validate/${order.data?.id}`, { replace: true });
      addPaidOrder(params.id);
    }
  }, [
    payWithCreditCard.data,
    payWithCreditCard.isError,
    payWithCreditCard.error,
    payWithCreditCard.isSuccess,
  ]);

  const shouldShowCreditCardForm =
    paymentMethod === null || !selectedCreditCard;

  return (
    <>
      <div
        className={`${
          shouldShowCreditCardForm && 'hidden'
        } fixed bottom-0 left-0 right-0 shadow-2xl p-4 bg-[#B21E1E] w-full h-[150px] text-white`}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col justify-between items-center h-full gap-2">
              <div className="flex flex-row items-center justify-center">
                <div className="text-1xl font-bold">Total: &nbsp;</div>
                <div className="text-1xl font-bold">{finalValueWithFees}</div>
              </div>

              <div className="flex flex-row items-center justify-center">
                <div className="text-1xl font-bold">CVV: &nbsp;</div>
                <TextInput
                  name="cvv"
                  className="w-20 text-[#B21E1E] font-bold rounded-lg p-2"
                  validationSchema={{}}
                  type="text"
                  value={cvv}
                  onChange={(event: any) => setCvv(event.target.value)}
                />
              </div>

              {cvv && (
                <button
                  className="bg-white w-full text-[#B21E1E] font-bold rounded-lg p-2"
                  type="submit"
                >
                  REALIZAR PAGAMENTO
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
      {payWithCreditCard.isLoading && <LoadingScreen />}
    </>
  );
};

export default PayWithCreditCard;
