import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import api from '../../services/api';
import { LoginResponse, MerchantProfile } from './types';

async function loginMerchant({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  //   const [, email, password] = ctx.queryKey;
  const { data } = await api.post<LoginResponse>('/login', {
    email,
    password,
    profile: 'api_merchant',
  });
  return data;
}

export async function customerAuth({ id }: { id: string }) {
  //   const [, email, password] = ctx.queryKey;
  const { data } = await api.post<LoginResponse>('/customers/login', {
    customer_id: id,
  });
  return data;
}

async function profileMerchant() {
  const { data } = await api.get<MerchantProfile>('/merchants');
  return data;
}

export function useLoginMerchant() {
  return useMutation(['login'], loginMerchant);
  //   return useQuery(['login', email, password], loginMerchant);
}

export function useProfileMerchant() {
  return useMutation(['profile'], profileMerchant);
  //   return useQuery(['login', email, password], loginMerchant);
}

// export function useAuthPhone() {
//   return useMutation(['auth-phone'], authPhone);
//   //   return useQuery(['login', email, password], loginMerchant);
// }
