export const pixBase64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAMAAAAPdrEwAAABKVBMVEUAAAA1v68zva8zva0yva0yva00va8zvK0zv7Mzva4zwbAyva4zvK0yv60zvK45xrgzwq07xLEzv7Myva41va4yvK0zvK0zva0yvK0zvq4zvK8zvrA2w643v60zzMw0vK8yva0zzLtAv78zva4yva42ya5V//81vK8zva0yva4yvK0yva4zvK0zva4zvq41vrI1vq8zva0yvK00vq45vbMzvq4yva0zvK4zvK0zva41v7VAv780va8yvK4zva4zva04v68zvK4zva8zvK41v7AyvK40va9G0bk2v7FAv680vK01vq4zva43vLFAv7////80vq4zva4zva4zvK42wK5J27Yzva4yvq8zvLE5xsY2vq43yLYzva4zva4yva0zva04wa40vrEyvK0RdU0uAAAAYnRSTlMAMHy+2/NJzhS5Ld/eOPESGRooQmGo8Pq7clA3JhwFY+QPCIunEwNc9/567u3CgStD9v1eG26yz+HrGAyP+OMyINIj4kTpWQs0EFQ/qi4EAZTT+7A5B5pWQQkvDujy4GQpJ4YKpPYAAAABb3JOVAHPoneaAAAAAXNSR0IArs4c6QAAApZJREFUWMPF2WlX00AUBuBp2tCNghUpKouoRamATkBlLwho3RWURQGX+f8/ApvkIG2SmfdOcg/zDU76nJ505s5dhABXzskXXLeQd3Ii2zVQVJerOJAhXCqrnlUuZSVXqqpvDVaykWtDKrKGalxyNna8nIWdJKe3k+W0tk5OZ+vlNLZJtrfNsq2NyHY2JtvYqEy3cZlqU2SaTZMpNlXGbboM28M36jdHbo02QHXs9p274xOTlJ9y6t408nWd+zbn8cFDo9ycgbVHvX8+ntXCrSeEXdeY6675wYWnz/x/SE8jLy75zzx/8XJ5pbtWa9jeaDXXDHYgr29sAvukb9e1t7R2IG+/QvZgZD83xjV2IO/sIvs75qQE9p4Hy/F27BlMtJPkODvhdCfYyXLUTowbsbZO7rc1ESnG1su9tjbWRWyTfNWu6KNonx3KbW3QCvPvUtUQg3ps83f2c/ugbigb49sVG/nOfk3iV0BAVA7skdfKfYPJSnVrqaKC7c7bd6isiv/qQeyaCmyBvOdw5YQDXoGN96H8YRr7gCPy8K398dNn8WXiawt8PC8KhJRgdt/FHy4IwsO05XLSlBeyuHtAeSH4z/jte0ccbh3hPyO8+Y7DzXcCbz6LI9MGjwzloP/4eUo56ITwdHKmzknhCQ6q0oPDdRhU0atAevAlo1S1RLjApAdfjf8vMOjalZFrt42VH8ZkQdomC8YUR9qnOIbETKZJzLTppEyXTmqSYJk2CeZM3TkLDs4yyS/u5ruLXNz9MhV3nCUpuZAenbGBp35nX/4P1+t/qE2Lv5PX3WphbBBxtrU4m3GcLUTOxidnu5azyczZGuds6HOOITiHJ5wjH85BFed4jXMoyDnK5BzAco6NOYfddiP6C3RjVR9Uwuk3AAAAAElFTkSuQmCC';

export const estados = [
  {
    id: 'AC',
    estado: 'Acre',
  },
  {
    id: 'AL',
    estado: 'Alagoas',
  },
  {
    id: 'AM',
    estado: 'Amazonas',
  },
  {
    id: 'AP',
    estado: 'Amapá',
  },
  {
    id: 'BA',
    estado: 'Bahia',
  },
  {
    id: 'CE',
    estado: 'Ceará',
  },
  {
    id: 'DF',
    estado: 'Distrito Federal',
  },
  {
    id: 'ES',
    estado: 'Espírito Santo',
  },
  {
    id: 'GO',
    estado: 'Goiás',
  },
  {
    id: 'MA',
    estado: 'Maranhão',
  },
  {
    id: 'MG',
    estado: 'Minas Gerais',
  },
  {
    id: 'MS',
    estado: 'Mato Grosso do Sul',
  },
  {
    id: 'MT',
    estado: 'Mato Grosso',
  },
  {
    id: 'PA',
    estado: 'Pará',
  },
  {
    id: 'PB',
    estado: 'Paraíba',
  },
  {
    id: 'PE',
    estado: 'Pernambuco',
  },
  {
    id: 'PI',
    estado: 'Piauí',
  },
  {
    id: 'PR',
    estado: 'Paraná',
  },
  {
    id: 'RJ',
    estado: 'Rio de Janeiro',
  },
  {
    id: 'RN',
    estado: 'Rio Grande do Norte',
  },
  {
    id: 'RO',
    estado: 'Rondônia',
  },
  {
    id: 'RR',
    estado: 'Roraima',
  },
  {
    id: 'RS',
    estado: 'Rio Grande do Sul',
  },
  {
    id: 'SC',
    estado: 'Santa Catarina',
  },
  {
    id: 'SP',
    estado: 'São Paulo',
  },
  {
    id: 'SE',
    estado: 'Sergipe',
  },
  {
    id: 'TO',
    estado: 'Tocantins',
  },
];

export const loadingSvg = (
  <svg
    className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export const typeToHuman: Record<string, string> = {
  DELIVERY: 'Entrega',
  PENDING: 'Pendente',
  REJECTED: 'Cancelado X',
  PREPARATION: 'Em Preparo',
  OUT_FOR_DELIVERY: 'Saiu Para Entrega',
  DELIVERED: 'Entregue',
  WITHDRAWAL: 'Retirada',
};

export const typeToHumanColor = {
  DELIVERY: { color: '#0088ff', label: 'Entrega' },
  PENDING: { color: '#ff8400', label: 'Pendente' },
  REJECTED: { color: '#c7000a', label: 'Cancelado' },
  CANCELED: { color: '#c7000a', label: 'Cancelado' },
  FAILED: { color: '#c7000a', label: 'Falha' },
  WHATSAPP: { color: '#9c9c9c', label: 'Via whatsapp' },
  PREPARATION: { color: '#ff8400', label: 'Em preparo' },
  OUT_FOR_DELIVERY: { color: '#ff8400', label: 'Saiu para entrega' },
  DELIVERED: { color: '#3da628', label: 'Entregue' },
  WITHDRAWAL: { color: '#3da628', label: 'Retirada' },
};

export const pagarmeToHumanColor = {
  processing: { color: '#ff8400', label: 'Processando' },
  captured: { color: '#3da628', label: 'Pago' },
  authorized: { color: '#3da628', label: 'Autorizado' },
  paid: { color: '#3da628', label: 'Pago' },
  canceled: { color: '#c7000a', label: 'Cancelado' },
  failed: { color: '#c7000a', label: 'Falha no pagamento' },
  not_authorized: { color: '#c7000a', label: 'Não autorizado' },
  refunded: { color: '#c7000a', label: 'Estornado' },
  pending: { color: '#ff8400', label: 'Aguardando pagamento' },
  waiting_payment: { color: '#ff8400', label: 'Aguardando pagamento' },
  pending_refund: { color: '#ff8400', label: 'Aguardando estorno' },
  refused: { color: '#c7000a', label: 'Recusado' },
  chargedback: { color: '#c7000a', label: 'Chargeback' },
  analyzing: { color: '#ff8400', label: 'Em análise' },
  pending_review: { color: '#ff8400', label: 'Revisão' },
};
