import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CacheResponse, Merchant } from '../queries/home/types';
import { LoginResponse } from '../queries/login/types';

type UserStore = {
  merchant_id: string;
  access_token: string;
  login: LoginResponse | undefined;
  merchant: Merchant | undefined;
  merchantCache: CacheResponse['content'] | undefined;
  user: {
    name: string;
    email: string;
    phone: string;
    taxpayer_id_number: string;
  };
  setMerchant: (merchant: Merchant) => void;
  setMerchantId: (merchant: string) => void;
  setLogin: (login: LoginResponse) => void;
  setMerchantCache: (cache: CacheResponse['content']) => void;
  setUserProperty: (property: string, value: string) => void;
  setUser: (user: UserStore['user']) => void;
};

export const useUserStore = create(
  persist<UserStore>(
    (set) => ({
      merchant_id: '',
      merchant: undefined,
      login: undefined,
      merchantCache: undefined,
      access_token: '',
      user: {
        name: '',
        email: '',
        phone: '',
        taxpayer_id_number: '',
      },
      setUser: (user) => {
        set((state) => ({
          user: user,
        }));
      },
      setUserProperty: (property, value) => {
        set((state) => ({
          user: {
            ...state.user,
            [property]: value,
          },
        }));
      },
      setMerchantCache: (cache) => {
        set((state) => ({
          merchantCache: cache,
        }));
      },
      setMerchantId(merchant) {
        set((state) => ({
          merchant_id: merchant,
        }));
      },
      setLogin: (login: LoginResponse) =>
        set((state) => ({
          login: login,
          access_token: login.access_token,
        })),
      setMerchant: (merchant: Merchant) =>
        set((state) => ({
          merchant: merchant,
        })),
    }),
    { name: 'user' },
  ),
);
