import { CacheResponse } from '../queries/home/types';
import { OrderDetailsResponse } from '../queries/order/types';

export function useCalculateFeesFinalValue(
  merchantCache: CacheResponse | undefined,
  order: OrderDetailsResponse | null | undefined,
  selectedPaymentMethod: string | null,
  frete: number | undefined,
  totalCart: number | undefined,
) {
  if (!totalCart) return 0;
  const account_fee_credit_card = merchantCache?.content.accounts.find(
    (item) => item.type === 'BANK' && item?.fees?.status === true,
  );
  const account_fee_pix = merchantCache?.content.accounts.find(
    (item) => item.type === 'BANK' && item?.fees?.status === true,
  );

  const fee_credit_card = account_fee_credit_card
    ? account_fee_credit_card.fees?.fee_credit_card
    : 0;
  const fee_pix = account_fee_pix ? account_fee_pix.fees?.fee_pix : 0;

  const calculateFee = (value: number) => {
    if (
      (selectedPaymentMethod &&
        selectedPaymentMethod === 'CHECKOUT_CREDIT_CARD') ||
      selectedPaymentMethod === 'CHECKOUT_ONLINE_CREDIT_CARD'
    ) {
      const percentage = fee_credit_card / 100;
      const fee_result = value * percentage;
      const result = value + fee_result;
      return result;
    } else if (
      selectedPaymentMethod === 'CHECKOUT_PIX' ||
      selectedPaymentMethod === 'CHECKOUT_ONLINE_PIX'
    ) {
      const percentage = fee_pix / 100;
      const fee_result = value * percentage;
      const result = value + fee_result;

      return result;
    } else {
      return value;
    }
  };

  const valorFinalSemPesagem = (
    calculateFee(totalCart) + (frete ?? 0)
  ).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  return valorFinalSemPesagem;
}
