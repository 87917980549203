import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import api from '../../services/api';
import { CacheResponse } from './types';

async function merchantCache(ctx: QueryFunctionContext) {
  const [, merchantId] = ctx.queryKey;
  if (!merchantId) return;
  const { data } = await api.get<CacheResponse>(
    `/all/merchants/${merchantId}/products/cache`,
  );
  return data;
}

export function useMerchantCache(id: string | undefined) {
  return useQuery(['merchantCache', id], merchantCache, {
    refetchOnWindowFocus: true,
    staleTime: 2000000,
  });
}
