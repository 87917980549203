import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { PixResponse } from '../queries/bank/types';

type PixStore = {
  timeGenerated: number;
  generatedPix: Record<string, PixResponse | null>;
  setGeneratedPix: (order_id: string, pix: PixResponse) => void;
};

export const usePixStore = create(
  persist<PixStore>(
    (set) => ({
      generatedPix: {},
      timeGenerated: 0,
      setGeneratedPix: (order_id, pix) => {
        set((state) => ({
          generatedPix: {
            ...state.generatedPix,
            [order_id]: pix,
          },
          timeGenerated: Date.now(),
        }));
      },
    }),
    {
      name: 'pix',
      getStorage: () => localStorage,
    },
  ),
);
