import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import api from '../../services/api';
import { customerAuth } from '../login';
import {
  CreateOrderResponse,
  FreightResponse,
  OrderDetailsResponse,
  OrderPayload,
} from './types';

async function getStatusPix(
  ctx: QueryFunctionContext,
): Promise<AxiosResponse<OrderDetailsResponse>> {
  const [, id] = ctx.queryKey;
  let response = await api.get('/customers/orders/status/' + id);
  return response;
}

/*
let headers = Headers.getAuthHeaders();
    let response = await api.get("/merchants/orders/" + id, {
      headers: headers,
    });
    return checkApiError(response);
*/

async function getFreight(ctx: QueryFunctionContext) {
  // id: string,
  // lat_origin: string,
  // long_origin: string,
  // lat_destiny: string,
  // long_destiny: string,
  // amount: number
  const [
    ,
    id,
    lat_origin,
    long_origin,
    lat_destiny,
    long_destiny,
    district,
    amount,
  ] = ctx.queryKey;
  if (!id || !lat_destiny || !long_destiny || !amount || !district) return null;
  const { data } = await api.post<FreightResponse>(
    '/customers/orders/freight/' + id,
    {
      lat_origin: lat_origin,
      long_origin: long_origin,
      lat_destiny: lat_destiny,
      long_destiny: long_destiny,
      district: district,
      amount: amount,
    },
  );
  return data;
}

async function getOrderDetails(ctx: QueryFunctionContext) {
  const [, id] = ctx.queryKey;
  if (!id) return null;
  const { data } = await api.get<OrderDetailsResponse>(
    '/customers/orders/' + id,
  );
  return data;
}

export function useGetOrderDetails(id?: string) {
  return useQuery(['getOrderDetails', id], getOrderDetails, {
    refetchOnWindowFocus: true,
    staleTime: 2000000,
  });
}

export function useGetStatusPix(id: string) {
  return useQuery(['getStatusPix', id], getStatusPix);
}

export function useGetFreight(
  lat_destiny: string,
  long_destiny: string,
  amount: number,
  district: string,
  lat_origin: string = '',
  long_origin: string = '',
  id: string = '',
) {
  return useQuery(
    [
      'getFreight',
      id,
      lat_origin,
      long_origin,
      lat_destiny,
      long_destiny,
      district,
      amount,
    ],
    getFreight,
  );
}
