import XCircleIcon from '@heroicons/react/24/outline/XCircleIcon';
import { Button } from '@material-tailwind/react';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import { useGetOrderDetails } from '../../queries/order';

const ErrorPage: React.FC = () => {
  const params = useParams();

  const { data, isSuccess, isLoading } = useGetOrderDetails(params.orderid);

  return (
    <div className="w-screen h-screen bg-white absolute top-0 right-0 left-0 z-10 p-6">
      <div className="flex flex-col items-center justify-center h-full">
        {isLoading ? (
          <LoadingScreen />
        ) : isSuccess && data ? (
          <>
            <XCircleIcon className="w-20 h-20 text-red-500" />
            <h1 className="text-2xl font-bold text-center text-gray-700 mt-10">
              Erro no pagamento do pedido
            </h1>
            <Button
              variant="filled"
              className="bg-red-500 mt-6"
              placeholder={''}
            >
              <a
                href={`https://api.whatsapp.com/send?phone=+55${encodeURIComponent(
                  parseInt(data.merchant.phone.replace(/[^0-9]/g, '')),
                )}&text=${encodeURIComponent(
                  `Não estou conseguindo realizar o pagamento do pedido #${data.code}`,
                )}`}
              >
                Entre em contato com o açougue
              </a>
            </Button>
          </>
        ) : (
          <>
            <XCircleIcon className="w-20 h-20 text-red-500" />
            <h1 className="text-2xl font-bold text-center text-gray-700 mt-10">
              Pedido não existe
            </h1>
          </>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
