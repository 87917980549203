import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type OrdersStore = {
  paidOrders: string[];
  isPaid: (order_id: string) => boolean;
  addPaid: (order_id: string | undefined) => void;
};

export const useOrdersStore = create(
  persist<OrdersStore>(
    (set, get) => ({
      paidOrders: [],
      isPaid: (order_id: string) => {
        console.log('paid', get().paidOrders);
        for (let id of get().paidOrders) {
          if (id === order_id) {
            return true;
          }
        }
        return false;
      },
      addPaid: (order_id: string | undefined) => {
        if (order_id === undefined) return;
        set((state) => ({ paidOrders: [...state.paidOrders, order_id] }));
      },
    }),
    { name: 'orders' },
  ),
);
