import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type PaymentMethodStore = {
  paymentMethod: string | null;
  lastTimeVisited: number;
  selectedCreditCard: string | null;
  setPaymentMethod: (paymentMethod: string | null) => void;
  setLastTimeVisited: (lastTimeVisited: number) => void;
  setSelectedCreditCard: (selectedCreditCard: string | null) => void;
};

export const usePaymentMethodStore = create(
  persist<PaymentMethodStore>(
    (set) => ({
      paymentMethod: null,
      selectedCreditCard: null,
      lastTimeVisited: 0,
      setSelectedCreditCard: (selectedCreditCard: string | null) => {
        set((state) => ({
          selectedCreditCard,
        }));
      },
      setLastTimeVisited: (lastTimeVisited: number) => {
        set((state) => ({
          lastTimeVisited,
        }));
      },
      setPaymentMethod: (paymentMethod: string | null) => {
        set((state) => ({
          paymentMethod,
        }));
      },
    }),
    {
      name: 'payment-method',
      getStorage: () => localStorage,
      version: 1,
    },
  ),
);
