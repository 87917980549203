import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import CreditCardPayment from '../../components/CreditCardPayment';
import ExistingCreditCards from '../../components/ExistingCreditCards';
import PayOnFinish from '../../components/PayOnFinish';
import PayOnline from '../../components/PayOnline';
import PhoneValidationScreen from '../../components/PhoneValidation';
import PixPayment from '../../components/PixPayment';
import UserDetailsComponent from '../../components/UserDetailsComponent';
import { useCalculateFeesFinalValue } from '../../hooks/useCalculateFeesFinalValue';
import { useDeliveryMethods } from '../../queries/bank';
import { useMerchantCache } from '../../queries/home';
import { customerAuth } from '../../queries/login';
import { useGetOrderDetails } from '../../queries/order';
import { usePaymentMethodStore } from '../../stores/payment-method';
import { usePhoneValidationStore } from '../../stores/phone-validation';
import { usePixStore } from '../../stores/pix';
import { useUserStore } from '../../stores/user';
import { pixBase64 } from '../../utils/constants';
import { currencyFormat } from '../../utils/currency-format';
import PayWithCreditCard from '../../components/PayWithCreditCard';
import { useGetHaveCards } from '../../queries/payment-card';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

const HomePage: React.FC<any> = () => {
  const params = useParams();
  const { data } = useVisitorData();
  const [newCard, setNewCard] = React.useState(false);
  const order = useGetOrderDetails(params.id);
  const setLogin = useUserStore((state) => state.setLogin);
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const generatedPix = usePixStore((state) => state.generatedPix);
  const order_id = params.id ?? '';
  const paymentMethod = usePaymentMethodStore((state) => state.paymentMethod);

  const haveCards = useGetHaveCards();

  const isValidated = usePhoneValidationStore((state) => state.isValidated);
  const setIsValidated = usePhoneValidationStore(
    (state) => state.setIsValidated,
  );

  const lastTimeVisited = usePaymentMethodStore(
    (state) => state.lastTimeVisited,
  );
  const setLastTimeVisited = usePaymentMethodStore(
    (state) => state.setLastTimeVisited,
  );

  const setPaymentMethod = usePaymentMethodStore(
    (state) => state.setPaymentMethod,
  );

  React.useEffect(() => {
    async function fetchData() {
      const authData = await customerAuth({
        id: params.customerid!,
      });
      console.log('auth', authData);
      setLogin(authData);
      localStorage.setItem('access_token', authData.access_token);
    }
    fetchData();

    const pixAlreadyExists = generatedPix[order_id ?? ''] !== undefined;
    if (pixAlreadyExists) {
      setPaymentMethod('CHECKOUT_PIX');
    }
    const TEN_MINUTES = 600000;
    console.log('setPaymentMethod');
    if (lastTimeVisited === 0) {
      setLastTimeVisited(new Date().getTime());
    } else if (new Date().getTime() - lastTimeVisited > TEN_MINUTES) {
      setPaymentMethod(null);
    }
  }, []);

  React.useEffect(() => {
    haveCards.mutate(data?.visitorId);
  }, [data?.visitorId]);

  const merchantCache = useMerchantCache(order.data?.merchant_id);
  const deliveryMethods = useDeliveryMethods(order.data?.merchant_id);

  const finalValueWithFees = useCalculateFeesFinalValue(
    merchantCache.data,
    order.data,
    paymentMethod,
    order.data?.freight_amount,
    parseFloat(order.data?.balance_amount ?? '0'),
  );

  const shouldVerifyPhone =
    haveCards.isSuccess && haveCards?.data?.haveCards === undefined;

  React.useEffect(() => {
    if (haveCards.data?.haveCards) {
      setIsValidated(true);
    } else {
      setIsValidated(false);
    }
  }, [haveCards.isSuccess, haveCards.data?.haveCards]);

  if (order.isLoading) {
    return (
      <div className="w-full h-auto bg-[#EBEBEB] relative">Loading...</div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-[#EBEBEB] relative">
      {/* header */}
      <div className="w-full h-[110px] flex flex-col p-4 bg-[#B21E1E] absolute top-0 left-0 right-0">
        <div className="flex flex-row content-between justify-between w-full">
          <div className="text-white cursor-pointer">
            {order.data?.merchant.name}
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="flex flex-col w-full h-auto bg-[#EBEBEB] absolute top-0 right-0 left-0 mt-[100px] rounded-tl-[20px] rounded-tr-[20px] p-6 mb-20">
        Ola {user.name},
        <br />
        <div className="mt-[20px]">
          <UserDetailsComponent />
        </div>
        <br />
        <br />
        {user.name &&
        user.email &&
        user.phone &&
        user.taxpayer_id_number?.length === 14 ? (
          <>
            <div className="mt-[0px]">
              {paymentMethod === null && (
                <>
                  {/* Formas de pagamento */}
                  {/* Pague Online */}
                  <PayOnline merchantCache={merchantCache.data} />
                  {/* Pague na Entrega */}
                  {/* <PayOnFinish deliveryMethods={deliveryMethods.data} /> */}
                </>
              )}
            </div>
            {paymentMethod === 'CHECKOUT_PIX' && (
              <div className="w-full h-auto bg-white rounded-[10px] p-4 mt-4">
                <div className="font-bold text-1xl">Pague com Pix</div>
                <div className="flex flex-row justify-between rounded-lg border-[2px] border-[#B21E1E] p-4 items-center mt-2">
                  <div className="flex flex-row items-center justify-center">
                    <img
                      src={pixBase64}
                      alt="pix"
                      className="w-[50px] h-[50px]"
                    />
                    <div className="flex flex-col ml-4">
                      <div className="font-bold text-1xl">Pix</div>
                      <div className="text-sm text-gray-400">
                        {/* {merchantCache.data?.pix_key} */}
                      </div>
                    </div>
                  </div>
                </div>

                <PixPayment />
              </div>
            )}
            {paymentMethod === 'CHECKOUT_CREDIT_CARD' && (
              <div className="w-full h-auto bg-white rounded-[10px] p-4 mt-4">
                <div className="font-bold text-1xl">
                  Pague com Cartão de Crédito
                </div>
                <div className="flex flex-row justify-between rounded-lg border-[2px] border-[#B21E1E] p-4 items-center mt-2">
                  <div className="flex flex-row items-center justify-center">
                    <img
                      src="https://2tspace.sfo3.cdn.digitaloceanspaces.com/metodos_pagamento%2Fcredit-card.png"
                      alt="pix"
                      className="w-[50px] h-[50px]"
                    />
                    <div className="flex flex-col ml-4">
                      <div className="font-bold text-1xl">
                        Cartão de Crédito
                      </div>
                      <div className="text-sm text-gray-400">
                        {/* {merchantCache.data?.pix_key} */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* TODO: to forçando aqui pra sempre mostrar o form de cartão */}
                {/* precisa ver se vai voltar a validar SMS */}
                {/* {isValidated ? ( */}
                {true ? (
                  <>
                    {' '}
                    {/* {!newCard && <ExistingCreditCards />} */}
                    {/* {!newCard && (
                      <div
                        className="flex flex-row justify-center items-center mt-4 rounded-lg border-2 border-[#b21e1e] border-dashed w-full h-[42px] cursor-pointer"
                        onClick={() => {
                          setNewCard(true);
                        }}
                      >
                        <div className="text-[#b21e1e]">+ Novo Cartão</div>
                      </div>
                    )} */}
                    {/* {newCard && (
                      <div
                        className="flex flex-row justify-center text-[#b21e1e] items-center mt-4 rounded-lg border-2 border-[#b21e1e] w-full h-[42px] cursor-pointer hover:bg-[#b21e1e] hover:text-white"
                        onClick={() => {
                          setNewCard(false);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="#b21e1e"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10 19l-7-7m0 0l7-7m-7 7h18"
                          />
                        </svg>
                        <div className="pl-2"> Usar cartões existentes</div>
                      </div>
                    )} */}
                    {/* {newCard && (
                      <CreditCardPayment
                        goBack={() => {
                          // haveCards.reset();
                          haveCards.mutate(data?.visitorId);
                          setNewCard(false);
                        }}
                      />
                    )} */}
                    <CreditCardPayment
                      goBack={() => {
                        // haveCards.reset();
                        haveCards.mutate(data?.visitorId);
                        setNewCard(false);
                      }}
                    />
                  </>
                ) : shouldVerifyPhone ? (
                  <PhoneValidationScreen />
                ) : null}
              </div>
            )}
            {/* TODO: decidir se vai permitir o usuario validar mais de um pagamento */}
            {/* {paymentMethod !== null && (
              // button to choose a different payment method
              <div
                className="flex flex-row justify-center items-center rounded-lg border-[2px]  bg-[#B21E1E] text-white p-3 mt-4 cursor-pointer"
                onClick={() => setPaymentMethod(null)}
              >
                <div className="font-bold text-1xl">
                  Escolher outra forma de pagamento
                </div>
              </div>
            )} */}
          </>
        ) : null}
        <br />
        <br />
        <div className="grid grid-cols-12">
          {order?.data?.items?.map((item) => {
            const quantidade = parseFloat(item.quantity);
            let total =
              item.price * quantidade -
              item.price * quantidade * (item.discount / 100);

            if (item.price_per_gram && item.grams) {
              total = item.price_per_gram * item.grams;
            }

            if (!item.coins) {
              return (
                <React.Fragment key={item.id}>
                  {item.price_per_gram ? (
                    <div className="col-span-2">
                      <p className="font-bold">
                        <span>{item.grams}g</span>
                      </p>
                    </div>
                  ) : item.product?.estimated_weight ? (
                    <div className="col-span-2">
                      <p className="font-bold">
                        <span>
                          {quantidade * item.product?.estimated_weight}g
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div className="col-span-2">
                      <p className="font-bold">
                        <span>{quantidade.toFixed(0)}x</span>
                      </p>
                    </div>
                  )}
                  <div className="col-span-7">
                    <p className="font-bold">
                      <span>{item.product?.name}</span>
                    </p>
                  </div>
                  <div className="col-span-3 text-end">
                    <p>
                      <span>{currencyFormat(total)}</span>
                    </p>
                  </div>
                  {item.comments ? (
                    <div className="col-span-12">
                      <p className="text-gray-600">
                        <span>{item.comments}</span>
                      </p>
                    </div>
                  ) : null}
                  {item.complements?.map((complement) => {
                    const quantity = complement.pivot.quantity;
                    const itemsKg = complement.complement.items_kg === 1;
                    const name = complement.name;
                    const total =
                      complement.pivot.quantity * complement.pivot.price;

                    const prefix =
                      itemsKg && item.product?.estimated_weight != null
                        ? quantity * item.product?.estimated_weight + 'g'
                        : quantity + 'x';

                    return (
                      <React.Fragment key={complement.id}>
                        <div className="col-span-11">
                          {complement.pivot.price > 0 ? (
                            <p className="text-gray-600 text-body2">
                              <span>
                                {`${prefix} ${name} - ${currencyFormat(total)}`}
                              </span>
                            </p>
                          ) : complement.pivot.grams ? (
                            <p className="text-gray-600 text-body2">
                              <span>{`${complement.pivot.grams}g ${complement.name}`}</span>
                            </p>
                          ) : (
                            <p className="text-gray-600 text-body2">
                              <span>{`${prefix} ${complement.name}`}</span>
                            </p>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </div>
        <br />
        <div className="mt-[20px]"></div>
        <div className="flex flex-row justify-between font-bold text-1xl">
          <div>Frete:</div>
          <div> {currencyFormat(order.data?.freight_amount ?? 0)}</div>
        </div>
        <div className="flex flex-row justify-between font-bold text-1xl">
          <div>Total:</div>
          <div>
            {' '}
            {/* {currencyFormat(parseFloat(order.data?.balance_amount ?? '0'))} */}
            {finalValueWithFees}
          </div>
        </div>
      </div>
      <PayWithCreditCard />
    </div>
  );
};

export default HomePage;
