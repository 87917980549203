import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from '@onbeef/components/input';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useParams } from 'react-router';
import Yup from '@onbeefapp/constants/src/utils/Yup';
import { useGetOrderDetails } from '../queries/order';
import { useUserStore } from '../stores/user';
import { maskCNPJ, maskCPF } from '../utils/input-masks';

type Inputs = {
  name: string;
  email: string;
  phone: string;
  taxpayer_id_number: string;
};

const UserDetailsComponent: React.FC<any> = () => {
  const params = useParams();
  const order = useGetOrderDetails(params.id);
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const [isCnpj, setIsCnpj] = React.useState(false);

  //   use react-hook-form and yup to validate the form
  const schema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().email('Email inválido').required('Email é obrigatório'),
    phone: Yup.string().required('Telefone é obrigatório'),
    taxpayer_id_number: Yup.string().required('CPF é obrigatório'),
  });

  const methods = useForm<Inputs>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      name: order.data?.name,
      phone: order.data?.phone,
    },
  });
  const errors = methods.formState.errors;

  useFormPersist('order-' + order.data?.id, {
    watch: methods.watch,
    setValue: methods.setValue,
  });

  const name = methods.watch('name');
  const phone = methods.watch('phone');
  const email = methods.watch('email');
  const taxpayer_id_number = methods.watch('taxpayer_id_number');

  const taxpayer_id_number_invalid = isCnpj
    ? cnpj.isValid(taxpayer_id_number?.replaceAll('.', '').replaceAll('-', ''))
    : cpf.isValid(taxpayer_id_number?.replaceAll('.', '').replaceAll('-', ''));

  React.useEffect(() => {
    if (taxpayer_id_number?.length > 14) {
      setIsCnpj(true);
    } else {
      setIsCnpj(false);
    }
    setUser({
      name,
      phone,
      email,
      taxpayer_id_number,
    });
  }, [name, phone, email, taxpayer_id_number]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit((data) => console.log(data))}>
        <div className="w-full mt-2 rounded-md">
          <TextInput
            name="email"
            customLabel="Email"
            validationSchema={{}}
            type={'text'}
            autoComplete="email"
            className="h-[42px] w-full text-sm"
            error={errors?.email !== undefined}
          />
          <div className="text-red-400">
            {errors?.email && errors?.email?.message}
          </div>
        </div>

        {isCnpj && (
          <div className="w-full mt-2 rounded-md">
            <TextInput
              name="taxpayer_id_number"
              customLabel="CNPJ"
              validationSchema={{}}
              type={'text'}
              autoComplete="cnpj"
              className="h-[42px] w-full text-sm"
              error={errors?.taxpayer_id_number !== undefined}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                methods.setValue(
                  'taxpayer_id_number',
                  maskCNPJ(e.target.value),
                );
                methods.trigger('taxpayer_id_number');
              }}
            />
            <div className="text-red-400">
              {errors?.taxpayer_id_number &&
                errors?.taxpayer_id_number?.message}
            </div>
          </div>
        )}
        {!isCnpj && (
          <div className="w-full mt-2rounded-md">
            <TextInput
              name="taxpayer_id_number"
              customLabel="CPF"
              validationSchema={{}}
              type={'text'}
              autoComplete="cpf"
              className="h-[42px] w-full text-sm"
              error={errors?.taxpayer_id_number !== undefined}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                methods.setValue('taxpayer_id_number', maskCPF(e.target.value));
                methods.trigger('taxpayer_id_number');
              }}
            />
            <div className="text-red-400">
              {errors?.taxpayer_id_number &&
                errors?.taxpayer_id_number?.message}
            </div>
          </div>
        )}
        {!taxpayer_id_number_invalid && (
          <div className="text-red-400">CPF inválido</div>
        )}
      </form>
    </FormProvider>
  );
};

export default UserDetailsComponent;
