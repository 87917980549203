import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type PhoneValidationStore = {
  phone: string;
  code: string;
  fingerprintCode: string;
  isValidated: boolean;
  lastTimeAskedForCode: number;
  setPhone: (phone: string) => void;
  setCode: (code: string) => void;
  setFingerprintCode: (fingerprintCode: string) => void;
  setIsValidated: (isValidated: boolean) => void;
  setLastTimeAskedForCode: (lastTimeAskedForCode: number) => void;
};

export const usePhoneValidationStore = create(
  persist<PhoneValidationStore>(
    (set) => ({
      phone: '',
      code: '',
      fingerprintCode: '',
      isValidated: false,
      lastTimeAskedForCode: 0,
      setPhone: (phone: string) => {
        set((state) => ({
          phone,
        }));
      },
      setCode: (code: string) => {
        set((state) => ({
          code,
        }));
      },
      setFingerprintCode: (fingerprintCode: string) => {
        set((state) => ({
          fingerprintCode,
        }));
      },
      setIsValidated: (isValidated: boolean) => {
        set((state) => ({
          isValidated,
        }));
      },
      setLastTimeAskedForCode: (lastTimeAskedForCode: number) => {
        set((state) => ({
          lastTimeAskedForCode,
        }));
      },
    }),
    {
      name: 'phone-validation',
      getStorage: () => localStorage,
    },
  ),
);
