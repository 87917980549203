import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import 'config/global.css';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ValidateCents } from '@onbeef/components/validateCents';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/Home';
import SuccessPage from './pages/SuccessPage';
import { useValidateCents } from './queries/payment-card';

function App() {
  const router = createBrowserRouter([
    {
      path: '/:id/:customerid/:payment_method?',
      index: true,
      element: <HomePage />,
      loader: ({ params }) => {
        return {
          id: params.id,
          customerid: params.customerid,
          payment_method: params.payment_method,
        };
      },
      action: ({ params }) => ({
        id: params.id,
        customerid: params.customerid,
        payment_method: params.payment_method,
      }),
    },
    {
      path: '/validate/:orderid',
      index: true,
      element: <ValidateCents validateCents={useValidateCents()} />,
      loader: ({ params }) => {
        return {
          orderid: params.orderid,
        };
      },
      action: ({ params }) => ({
        orderid: params.orderid,
      }),
    },
    {
      path: '/success/:orderid',
      index: true,
      element: <SuccessPage />,
      loader: ({ params }) => {
        return {
          orderid: params.orderid,
        };
      },
      action: ({ params }) => ({
        orderid: params.orderid,
      }),
    },
    {
      path: '/error/:orderid',
      index: true,
      element: <ErrorPage />,
      loader: ({ params }) => {
        return {
          orderid: params.orderid,
        };
      },
      action: ({ params }) => ({
        orderid: params.orderid,
      }),
    },
  ]);

  React.useEffect(() => {
    document.body.classList.add('theme-black');
  }, []);

  return (
    <>
      <FpjsProvider
        loadOptions={{
          apiKey: 'egAi4h2sto4tVTja0KnT',
        }}
      >
        <RouterProvider router={router} />
        <ToastContainer />
      </FpjsProvider>
    </>
  );
}

export default App;
