import * as React from 'react';
import { CacheResponse } from '../queries/home/types';
import { usePaymentMethodStore } from '../stores/payment-method';
import { pixBase64 } from '../utils/constants';

type Props = {
  merchantCache?: CacheResponse;
};

const PayOnline: React.FC<Props> = ({ merchantCache }) => {
  const setPaymentMethod = usePaymentMethodStore(
    (state) => state.setPaymentMethod,
  );
  return (
    <div className="w-full h-auto bg-white rounded-[10px] p-4 ">
      <div className="text-1xl font-bold">Pague online</div>
      <div className="flex flex-row gap-[10px]">
        {merchantCache?.content?.accounts
          .filter((account) => {
            return account.status === 'ACTIVE' && account.type === 'BANK';
          })
          .map((item) => {
            return item.methods.map((method) => {
              return (
                <div
                  key={item.key}
                  className="flex flex-row w-full justify-between rounded-lg border-[2px] border-gray-400 hover:border-[#B21E1E] p-4 items-center mt-2"
                  onClick={() => {
                    setPaymentMethod('CHECKOUT_' + method);
                  }}
                >
                  <div className="flex flex-row items-center justify-center">
                    <img
                      src={
                        method.includes('PIX')
                          ? pixBase64
                          : 'https://www.freeiconspng.com/thumbs/credit-card-icon-png/credit-card-2-icon-7.png'
                      }
                      alt={method}
                      className="w-[50px] h-[50px]"
                    />
                    <div className="flex flex-col ml-4">
                      <div className="text-1xl font-bold">
                        {' '}
                        {method === 'CREDIT_CARD'
                          ? 'Cartão de crédito'
                          : method === 'DEBIT_CARD'
                            ? 'Cartão de débito'
                            : method === 'CASH'
                              ? 'Dinheiro'
                              : method === 'PIX'
                                ? 'Pix'
                                : method === 'BANK_SLIP'
                                  ? 'Boleto'
                                  : method === 'TRANSFER'
                                    ? 'Transferência'
                                    : method === 'CREDIT'
                                      ? 'Crédito'
                                      : method === 'DEBIT'
                                        ? 'Débito'
                                        : method === 'CREDIT_CARD_INSTALLMENT'
                                          ? 'Cartão de crédito parcelado'
                                          : method === 'DEBIT_CARD_INSTALLMENT'
                                            ? 'Cartão de débito parcelado'
                                            : method === 'CREDIT_INSTALLMENT'
                                              ? 'Crédito parcelado'
                                              : method === 'DEBIT_INSTALLMENT'
                                                ? 'Débito parcelado'
                                                : method ===
                                                    'CREDIT_CARD_INSTALLMENT'
                                                  ? 'Cartão de crédito parcelado'
                                                  : method ===
                                                      'DEBIT_CARD_INSTALLMENT'
                                                    ? 'Cartão de débito parcelado'
                                                    : method ===
                                                        'CREDIT_INSTALLMENT'
                                                      ? 'Crédito parcelado'
                                                      : method ===
                                                          'DEBIT_INSTALLMENT'
                                                        ? 'Débito parcelado'
                                                        : method.toString()}
                      </div>
                      <div className="text-sm text-gray-400"></div>
                    </div>
                  </div>
                </div>
              );
            });
          })}
      </div>
    </div>
  );
};

export default PayOnline;
